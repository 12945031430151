import React, { createContext, useEffect, useState } from "react";
import { UserProfile } from "../api";
import { TMarketItemWithPrice } from "../types";

export const AppContext = createContext<{
  orderData: TMarketItemWithPrice | null;
  setOrderData: (item: TMarketItemWithPrice) => null;
  userData: UserProfile | null;
  setUserData: (data: UserProfile | null) => void;
}>({
  //default value is required now for react context
  orderData: null,
  setOrderData: (item: TMarketItemWithPrice) => null,
  userData: null,
  setUserData: (data) => {},
});

export default function AppProvider(props: any) {
  const [orderData, setOrderData] = useState<TMarketItemWithPrice | null>(null);
  const [userData, setUserData] = useState<UserProfile | null>(null);

  const checkAuth = () => {
    if (sessionStorage.getItem("userData")) {
      setUserData(JSON.parse(sessionStorage.getItem("userData") as string));
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const updateUserData = (userData: UserProfile | null) => {
    setUserData(userData);
    sessionStorage.setItem("userData", JSON.stringify(userData));
  };

  console.log("userData: ", userData);
  return (
    <AppContext.Provider
      value={{
        orderData,
        setOrderData,
        userData,
        setUserData: updateUserData,
      }}
      {...props}
    />
  );
}
