import { TMarketItem } from "../types";

export const SQUARES_ROWS_MAIN_FULL = [
  {
    id: 1,
    count: 12,
    startCoord: {
      top: 0,
      left: 5,
      bias: 72,
    },
    rowMargin: 222,
    rowMinWidth: "712px",
  },
  {
    id: 2,
    count: 11,
    startCoord: {
      top: 80,
      left: 222,
      bias: 72,
    },
    rowMargin: 74,
    rowMinWidth: "712px",
  },
  {
    id: 3,
    count: 9,
    startCoord: {
      top: 160, //160
      left: 432,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
  {
    id: 4,
    count: 8,
    startCoord: {
      top: 229,
      left: 504,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
  {
    id: 5,
    count: 6,
    startCoord: {
      top: 296,
      left: 576,
      bias: 72,
    },
    rowMargin: 73.5,
    rowMinWidth: "712px",
  },
  {
    id: 6,
    count: 8,
    startCoord: {
      top: 378, //378
      left: 504,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
  {
    id: 7,
    count: 9,
    startCoord: {
      top: 450, //50
      left: 432,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
  {
    id: 8,
    count: 10,
    startCoord: {
      top: 522,
      left: 216,
      bias: 72,
    },
    rowMargin: 148,
    rowMinWidth: "712px",
  },
  {
    id: 9,
    count: 12,
    startCoord: {
      top: 594, //94
      left: 0,
      bias: 72,
    },
    rowMargin: 222,
    rowMinWidth: "712px",
  },
];

export const SQUARES_ROWS_MAIN_MOBILE = [
  {
    id: 1,
    count: 9,
    startCoord: {
      top: 1,
      left: 90,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
  {
    id: 2,
    count: 11,
    startCoord: {
      top: 75,
      left: 13,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
  {
    id: 3,
    count: 11,
    startCoord: {
      top: 150,
      left: 13,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
  {
    id: 4,
    count: 9,
    startCoord: {
      top: 220,
      left: 90,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "712px",
  },
];

export const SQUARES_ROWS_LEAD_FULL = [
  {
    id: 1,
    count: 6,
    startCoord: {
      top: 0,
      left: 437,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "auto",
  },
  {
    id: 2,
    count: 8,
    startCoord: {
      top: 90,
      left: 437,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "auto",
  },
  {
    id: 3,
    count: 9,
    startCoord: {
      top: 160,
      left: 432,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "auto",
  },
  {
    id: 4,
    count: 9,
    startCoord: {
      top: 250,
      left: 503,
      bias: 72,
    },
    rowMargin: 74,
    rowMinWidth: "auto",
  },
  {
    id: 5,
    count: 6,
    startCoord: {
      top: 314,
      left: 575,
      bias: 72,
    },
    rowMargin: 148,
    rowMinWidth: "auto",
  },
  {
    id: 6,
    count: 9,
    startCoord: {
      top: 378,
      left: 504,
      bias: 72,
    },
    rowMargin: 74,
    rowMinWidth: "auto",
  },
  {
    id: 7,
    count: 6,
    startCoord: {
      top: 525,
      left: 437,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "auto",
  },
  {
    id: 8,
    count: 8,
    startCoord: {
      top: 600,
      left: 437,
      bias: 72,
    },
    rowMargin: 0,
    rowMinWidth: "auto",
  },
];

export const DESCRIPTION_CARD_DATA = [
  {
    title: "01",
    content:
      "AudienceHub анонимно собирает данные через скрипт на сайте, храня их у владельцев сайтов для соблюдения конфиденциальности.",
    background: "rgba(200, 200, 200, 1)",
    variant: "gray",
  },
  {
    title: "02",
    content: "Создавайте целевые сегменты на основе взаимодействий пользователей с контентом и размещайте их в маркетплейсе.",
    background: "rgba(199, 213, 255, 1)",
    variant: "slightlyBlue",
  },
  {
    title: "03",
    content:
      "Управляйте сегментами в личном кабинете, устанавливайте цены и продавайте доступ к аудиториям.",
    background: "rgba(33, 85, 254, 1)",
    variant: "blue",
  },
];

export const ACCORDION_ITEMS = [
  {
    title: "КАК ВЫБРАТЬ АУДИТОРИЮ?",
    description:
      "AudienceHub предлагает разнообразные сегменты аудитории. Выберите сегмент в маркетплейсе, чтобы настроить его под ваши метрики.",
  },
  {
    title: "КАК ОБЕСПЕЧИВАЕТСЯ КОНФИДЕНЦИАЛЬНОСТЬ ДАННЫХ?",
    description:
      "AudienceHub собирает и хранит данные анонимно на стороне владельцев сайтов, без обработки и хранения персональных данных.",
  },
  {
    title: "КАКИЕ ТИПЫ СЕГМЕНТОВ ДОСТУПНЫ?",
    description:
      "На AudienceHub доступны сегменты на основе взаимодействий с популярным контентом: недвижимость, финансы, технологии и другие темы.",
  },
  {
    title: "КАК НАЧАТЬ ПОЛЬЗОВАТЬСЯ?",
    description:
      "Зарегистрируйтесь, выберите сегмент, настройте метрики и запустите кампанию, ориентированную на целевых пользователей.",
  },
  {
    title: "КАК AUDIENCE HUB ПОМОГАЕТ ЭКОНОМИТЬ БЮДЖЕТ?",
    description:
      "Мы предоставляем доступ к уже заинтересованным пользователям, повышая конверсию и снижая расходы на рекламу.",
  },
  {
    title: "МОЖНО ЛИ ПРОТЕСТИРОВАТЬ ПЛАТФОРМУ ПЕРЕД ПОКУПКОЙ?",
    description:
      "Да, вы можете посмотреть примеры сегментов и их характеристики до покупки.",
  },
];

export const MARKET_ITEMS: TMarketItem[] = [
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В МОСКВЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В ТВЕРИ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
  {
    title: "ТОП-10 НОВОСТРОЕК В САМАРЕ ДЛЯ МОЛОДЫХ СЕМЕЙ",
    articleUrl: "/",
    tags: ["#недвижимость", "#жилье", "#жилье"],
    userTime: "01",
    userCount: "01",
    leadingUrl: "/",
  },
];
