import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from "../providers";
import { AppBar, Footer } from "../components";
import {
  Main,
  Contacts,
  Market,
  Order,
  OrderHistory,
  AccountSettings,
} from "../pages";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <BrowserRouter>
          <AppBar />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/market" element={<Market />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/order" element={<Order />} />
            <Route path="/order_history" element={<OrderHistory />} />
            <Route path="/account_settings" element={<AccountSettings />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
