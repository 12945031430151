import React from "react";
import css from "./styles.module.css";
import { Button } from "../";
import { Link, useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const onFollowLink = () => {
    navigate("/order");
  };

  const onScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <footer className={css.container}>
      <hr className={css.divider} />
      <div className={css.box}>
        <Link onClick={onScrollToTop} to="/" className={css.bigName}>
          AUDIENCE HUB
        </Link>
        <nav className={css.nav}>
          <Link onClick={onScrollToTop} to="/market" className={css.smallUrl}>
            Маркет аудиторий
          </Link>
          <Link onClick={onScrollToTop} to="/contacts" className={css.smallUrl}>
            Контакты
          </Link>
          <Button onClick={onFollowLink} variant="black">
            Заявка на демо
          </Button>
        </nav>
      </div>
      <div className={css.nameRow}>
        <div className={css.name}>© AudienceHub, 2024</div>
        <div className={css.docRow}>
          <a className={css.doc} download="Политика_конфиденциальности_AudienceHub.pdf" href="./privacy.pdf" target="_blank">
            Политика конфиденциальности
          </a>
          <a className={css.doc} download="Пользовательское_соглашение_AudienceHub.pdf" href="./userAgreement.pdf" target="_blank">
            Пользовательское соглашение
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
