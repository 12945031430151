import axios from "axios";
import {
  CategoryResponse,
  MarketFilter,
  OrderResponse,
  Profile,
  SegmentResponse,
  UserProfile,
} from "./types";

const api = axios.create({
  baseURL: "https://api.audiencehub.ru/api",
});

export const getSegments = async (
  page: number,
  perPage: number,
  filter?: MarketFilter,
) => {
  const url = "/v1/public/segments";

  if (!filter) {
    const response = await api.get<SegmentResponse>(url, {
      params: { page, per_page: perPage },
    });

    return response;
  }

  const response = await api.get<SegmentResponse>(url, {
    params: {
      page,
      per_page: perPage,
      "query[category_name_eq]": filter.category,
      "query[time_spent_gt]": filter.time_spent,
      "query[users_count_gt]": filter.user_count,
    },
  });

  return response;
};

export const getOrderHistory = async (page: number, perPage: number) => {
  const url = "/v1/public/orders";

  const response = await api.get<OrderResponse>(url, {
    params: { page, per_page: perPage },
  });

  return response;
};

export const postRegister = async (email: string, password: string) => {
  const url = "https://api.audiencehub.ru/sign_up";

  const response = await axios.post(url, { email, password });

  return response;
};

export const postLogin = async (email: string, password: string) => {
  const url = "https://api.audiencehub.ru/sign_in";

  const response = await axios.post<UserProfile>(url, { email, password });

  return response;
};

export const putProfile = async (profile: Partial<Profile>) => {
  const url = "/v1/profiles";
  const token = sessionStorage.getItem("token") ?? "";

  const response = await api.put(
    url,
    { data: profile },
    { headers: { Authorization: `Bearer ${token}` } },
  );

  return response;
};

export const getCategories = async () => {
  const url = "/v1/public/categories";

  const response = await api.get<CategoryResponse>(url);

  return response;
};
