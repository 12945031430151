import React, { useState, useEffect } from "react";
import css from "./styles.module.css";
import { Button, Mozaic } from "../../../components";
import { useNavigate } from "react-router-dom";
import {
  SQUARES_ROWS_MAIN_FULL,
  SQUARES_ROWS_MAIN_MOBILE,
} from "../../../constants";

const MozaicSection = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [mozaic, setMozaic] = useState("./fullScreenMozaic.jpg");
  const navigate = useNavigate();
  const isFullScreen = width >= 1380;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (width >= 1380) {
      setMozaic("./fullScreenMozaic.jpg");
    } else {
      setMozaic("mobileScreenMozaic.jpg");
    }
  }, [width]);

  const onFollowLink = () => {
    navigate("/order");
  };

  return (
    <section className={css.sectionContainer} style={{ overflow: "hidden" }}>
      <Mozaic
        img={mozaic}
        isFull={isFullScreen}
        squaresRows={
          isFullScreen ? SQUARES_ROWS_MAIN_FULL : SQUARES_ROWS_MAIN_MOBILE
        }
        squareJustifyContent={isFullScreen ? "end" : "center"}
        showImageAnyWay={!isFullScreen}
        wrapperStyles={
          !isFullScreen
            ? {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }
            : {}
        }
        startAnimation={true}
      />
      <div className={css.content}>
        <div className={css.sectionTitle}>
          AudienceHub — платформа для монетизации аудитории
        </div>
        <div className={css.sectionText}>
        AudienceHub помогает владельцам сайтов и СМИ продавать целевые сегменты пользователей, а рекламодателям — точно таргетировать уже заинтересованную аудиторию.
        </div>
        <div className={css.sectionButton}>
          <Button onClick={onFollowLink} variant="blue">
            Заявка на демо
          </Button>
        </div>
      </div>
    </section>
  );
};

export default MozaicSection;
