import React from "react";
import css from "./styles.module.css";
import {
  Button,
  Section,
  ArrowBack,
  ArrowForward,
  MarketItem,
} from "../../../components";
import classNames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSegments } from "../../../api";

function ExamplesSection() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { isLoading, data, isPreviousData } = useSegments(page, 2);

  const onForwardButtonClick = () =>
    setPage((page) =>
      !isPreviousData && data?.data.meta.page.next_page ? page + 1 : page,
    );

  const onBackButtonClick = () => setPage((page) => Math.max(page - 1, 0));

  const onFollowLink = () => {
    navigate("/market");
  };

  if (isLoading) {
    return null;
  }

  return (
    <Section title="Примеры аудиторий">
      <div className={css.wrapper}>
        <div className={css.examples}>
          {data?.data.data.map((example) => (
            <MarketItem
              key={example.id}
              title={example.name}
              tags={example.tags}
              userCount={String(example.users_count)}
              userTime={example.time_spent}
              articleUrl={example.article_link}
              leadingUrl={`/${example.id}`}
            />
          ))}
        </div>
        <div className={css.actions}>
          <div className={css.pagination}>
            <Button
              variant="black"
              styles={classNames(css.backButton, css.paginationButton)}
              onClick={onBackButtonClick}
            >
              <ArrowBack className={css.arrowIcon} />
            </Button>
            <Button
              variant="black"
              styles={css.paginationButton}
              onClick={onForwardButtonClick}
            >
              <ArrowForward className={css.arrowIcon} />
            </Button>
          </div>
          <div className={css.market}>
            <p className={css.text}>Ознакомьтесь с маркетом аудиторий</p>
            <div className={css.buttonBox}>
              <Button onClick={onFollowLink} styles={css.button}>
                Перейти в маркет
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ExamplesSection;
