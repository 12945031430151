import { TMarketItem, TMarketItemWithPrice } from "../../types";
import css from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { useAppContext } from "../../hooks";

type Props =
  | (TMarketItem & { layout?: "book " })
  | (TMarketItemWithPrice & { layout?: "album" });

function MarketItem(props: Props) {
  const {
    layout = "book",
    title,
    tags,
    articleUrl,
    userTime,
    userCount,
  } = props;

  const navigate = useNavigate();
  const { setOrderData } = useAppContext();

  const onBuy = () => {
    navigate("/order");
    setOrderData(props as TMarketItemWithPrice);
  };

  if (layout === "book") {
    return (
      <div className={css.example}>
        <div className={css.tags}>
          {tags.map((tag) => (
            <p className={css.tag}>{tag}</p>
          ))}
        </div>
        <div className={css.exampleBlock}>
          <h3 className={css.exampleTitle}>{title}</h3>
          <Link className={css.articleUrl} to={articleUrl}>
            Статья на сайте
          </Link>
          <div className={css.counters}>
            <div className={css.counter}>
              <p className={css.counterText}>Время пользователя на странице</p>
              <p className={css.counterValue}>{userTime}</p>
            </div>
            <hr className={css.separator} />
            <div className={css.counter}>
              <p className={css.counterText}>Актуальное число пользователей</p>
              <p className={css.counterValue}>{userCount}</p>
            </div>
          </div>

          <Button variant="black" styles={css.tryButton}>
            Попробовать аудиторию
          </Button>
        </div>
      </div>
    );
  }

  const price = "price" in props ? props.price : "0";

  return (
    <div className={css.exampleAlbum}>
      <div className={css.tags}>
        {tags.map((tag) => (
          <p className={css.tag}>{tag}</p>
        ))}
      </div>
      <div className={css.exampleBlockAlbum}>
        <div className={css.titleBlockAlbum}>
          <h3 className={css.exampleTitle}>{title}</h3>
          <Link className={css.articleUrl} to={articleUrl}>
            Статья на сайте
          </Link>
        </div>
        <div className={css.counters}>
          <hr className={css.separator} />
          <div className={css.counterAlbum}>
            <p className={css.counterText}>Время пользователя на странице</p>
            <p className={css.counterValue}>{userTime}</p>
          </div>
          <hr className={css.separator} />
          <div className={css.counterAlbum}>
            <p className={css.counterText}>Актуальное число пользователей</p>
            <p className={css.counterValue}>{userCount}</p>
          </div>
        </div>

        <div className={css.buyBlock}>
          <p className={css.price}>{price} руб</p>
          <Button variant="blue" styles={css.buyButton} onClick={onBuy}>
            Купить
          </Button>
        </div>
      </div>
    </div>
  );
}

export default MarketItem;
