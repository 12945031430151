import React from "react";
import css from "./styles.module.css";
import classNames from "classnames";

type TButton = {
  children: string | React.ReactNode;
  onClick?: () => void;
  variant?: "blue" | "black" | "white";
  styles?: string;
  type?: "button" | "submit" | "reset";
};

const Button = ({ children, onClick, variant, styles, type }: TButton) => {
  const buttonClasses = () => {
    switch (variant) {
      case "black":
        return css.blackVariant;
      case "white":
        return css.whiteVariant;
      case "blue":
      default:
        return css.blueVariant;
    }
  };

  return (
    <button
      onClick={onClick}
      className={classNames(css.button, buttonClasses(), styles)}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
