import { FormEvent, useState } from "react";
import { postLogin, postRegister } from "../../api";
import useAppHook from "../../hooks/useAppContext";
import Button from "../Button/Button";
import Input from "../Input/Input";
import css from "./styles.module.css";

type Props = {
  setAuthOpen: (open: boolean) => void;
};

function Auth({ setAuthOpen }: Props) {
  const { setUserData } = useAppHook();
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");

  const onRegister = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== passwordCheck) {
      return;
    }

    postRegister(email, password)
      .then(() => setIsLoginForm(true))
      .catch(() => {});
  };

  const onLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const response = await postLogin(email, password).catch(() => undefined);

    if (response?.status === 201) {
      const userData = response.data;
      const token = response.headers["x-session-token"];

      sessionStorage.setItem("token", token);
      sessionStorage.setItem("userData", JSON.stringify(userData))
      setUserData(userData);
      setAuthOpen(false);
    }
  };

  return (
    <>
      <div
        className={css.authBackground}
        onClick={() => setAuthOpen(false)}
      ></div>
      <div className={css.authRoot}>
        <div className={css.authContainer}>
          {isLoginForm && (
            <>
              <p className={css.authBigText}>ВОЙТИ В АККАУНТ</p>
              <form className={css.form} onSubmit={onLogin}>
                <Input
                  id="email"
                  label="ПОЧТА"
                  value={email}
                  onChange={(value) => setEmail(value)}
                />
                <Input
                  id="password"
                  label="ПАРОЛЬ"
                  value={password}
                  type="password"
                  onChange={(value) => setPassword(value)}
                />
                <Button variant="blue" type="submit">
                  Войти
                </Button>
                <p className={css.linkButton}>Сбросить пароль</p>
              </form>
              <div className={css.promptRegister}>
                <p className={css.noAccount}>Нет аккаунта?</p>
                <p
                  className={css.linkButton}
                  onClick={() => setIsLoginForm(false)}
                >
                  Создать
                </p>
              </div>
            </>
          )}
          {!isLoginForm && (
            <>
              <p className={css.authBigText}>СОЗДАТЬ АККАУНТ</p>
              <form className={css.form} onSubmit={onRegister}>
                <Input
                  id="email"
                  label="ПОЧТА"
                  value={email}
                  onChange={(value) => setEmail(value)}
                />
                <Input
                  id="password"
                  label="ПАРОЛЬ"
                  value={password}
                  type="password"
                  onChange={(value) => setPassword(value)}
                />
                <Input
                  id="password_check"
                  label="ПОВТОРИТЕ ПАРОЛЬ"
                  value={passwordCheck}
                  type="password"
                  onChange={(value) => setPasswordCheck(value)}
                />
                <Button variant="blue" type="submit">
                  Зарегистрироваться
                </Button>
              </form>
              <div className={css.promptRegister}>
                <p className={css.noAccount}>Есть аккаунт?</p>
                <p
                  className={css.linkButton}
                  onClick={() => setIsLoginForm(true)}
                >
                  Войти
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Auth;
