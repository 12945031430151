import React from "react";
import {
  AboutSection,
  ExamplesSection,
  FaqSection,
  LeadSection,
  MarketingSection,
  MozaicSection,
} from ".";

const Main = () => {
  return (
    <>
      <MozaicSection />
      <AboutSection />
      <MarketingSection />
      <ExamplesSection />
      <FaqSection />
      <LeadSection />
    </>
  );
};

export default Main;
