import React, { useState } from "react";
import { useAppContext } from "../../hooks";
import css from "./styles.module.css";
import classNames from "classnames";
import { Button } from "../../components";

const Order = () => {
  const [isRenderPlaceholder, setIsRenderPlaceholder] = useState(false);

  const { orderData } = useAppContext();

  return (
    <section className={css.section}>
      {isRenderPlaceholder ? (
        <div className={css.sectionTitle}>"Ждем эквайринг от Альфа-банк"</div>
      ) : (
        <>
          <div className={css.sectionTitle}>Оформление заказа</div>

          <div className={css.content}>
            <div className={classNames(css.wrapper)}>
              <div className={css.wrapperTitle}>ЗАКАЗ</div>
              <div className={css.wrapperContent}>
                {/* Временно убираем */}
                {/* <div className={css.row}>
                  <div className={css.title}>НОМЕР ЗАКАЗА</div>
                  <div className={css.value}>#254674</div>
                </div> */}

                <div className={css.row}>
                  <div className={css.title}>СЕГМЕНТ</div>
                  <div className={css.value}>{orderData?.title}</div>
                </div>

                <div className={css.row}>
                  <div className={css.title}>ЮЗЕРОВ В АУДИТОРИИ</div>
                  <div className={css.value}>{orderData?.userCount}</div>
                </div>

                <div className={css.row}>
                  <div className={css.title}>ВРЕМЕНИ НА СТРАНИЦЕ</div>
                  <div className={css.value}>{orderData?.userTime}</div>
                </div>
              </div>
            </div>

            <div className={classNames(css.wrapper, css.metric)}>
              {/* Временно убрали */}
              {/* <div className={css.wrapperTitle}>МЕТРИКА</div>
              <div className={css.description}>
                Чтобы предоставить вам покупаемый сегмент, нам нужен ваш ID
                Yandex Metrika. Ваши данные о сайте останутся
                конфиденциальными — мы не будем иметь доступ к вашим данным.
              </div>
              <div className={css.inputContainer}>
                <label className={css.label}>ID Yandex metrika</label>
                <input className={css.input} type="text" />
              </div>

              <div className={css.line} /> */}

              <div className={css.total}>
                <div className={css.text}>
                  Итого
                </div>
                <div className={css.cost}>{orderData?.price} руб</div>
              </div>

              <Button
                  onClick={() => setIsRenderPlaceholder(true)}
                  variant="blue"
                >
                  Купить
                </Button>

            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Order;
