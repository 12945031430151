import classNames from "classnames";
import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useOrderHistory } from "../../api";
import { ArrowBack, ArrowForward, Button } from "../../components";
import css from "./styles.module.css";

const STATUS_MAP: Record<string, string> = {
  ВЫПОЛНЕН: "#20DF39",
  ОТМЕНЕН: "#F95914",
};

const Status = ({ content }: { content: string }) => {
  return (
    <div className={classNames(css.statusBox)}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill={STATUS_MAP[content]} />
      </svg>
      <p>{content}</p>
    </div>
  );
};

function OrderHistory() {
  const [page, setPage] = useState(1);

  const { isLoading, data, isPreviousData } = useOrderHistory(page, 10);

  const availablePages = useMemo(() => {
    const maxPages = data?.data.meta.page.total_pages ?? 0;

    if (page === 1) {
      return Array(Math.min(maxPages, 3))
        .fill(0)
        .map((_, i) => i + 1);
    }

    if (page === maxPages) {
      return Array(3)
        .fill(0)
        .map((_, i) => maxPages - 2 + i);
    }

    return Array(3)
      .fill(0)
      .map((_, i) => page - 1 + i);
  }, [data, page]);

  const onForwardButtonClick = () =>
    setPage((page) =>
      !isPreviousData && data?.data.meta.page.next_page ? page + 1 : page,
    );

  const onBackButtonClick = () => setPage((page) => Math.max(page - 1, 1));

  const onPageNumberClick = (num: number) => setPage(num);

  return (
    <div className={css.container}>
      <h2 className={css.title}>История заказов</h2>
      <table className={css.table}>
        <thead>
          <tr className={css.tableHead}>
            <th className={classNames(css.name, css.tableCol)}>СЕГМЕНТ</th>
            <th className={classNames(css.num, css.tableCol)}>ДАТА ПОКУПКИ</th>
            <th className={classNames(css.num, css.tableCol)}>
              СТОИМОСТЬ СЕГМЕНТА
            </th>
            <th className={classNames(css.num, css.tableCol)}>
              ЮЗЕРОВ В АУДИТОРИИ
            </th>
            <th className={classNames(css.num, css.tableCol)}>
              ВРЕМЕНИ НА СТРАНИЦЕ
            </th>
            <th className={classNames(css.num, css.tableCol)}>НОМЕР ЗАКАЗА</th>
            <th className={classNames(css.num, css.tableCol)}>СТАТУС</th>
            <th className={classNames(css.metric, css.tableCol)}>ID МЕТРИКИ</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            data?.data.data.map((order) => (
              <tr className={css.tableRow}>
                <th
                  className={classNames(css.firstCell, css.tableCell, css.name)}
                >
                  <Link to="">{order.segment_name}</Link>
                </th>
                <th className={classNames(css.numCell, css.num)}>
                  {order.created_at}
                </th>
                <th
                  className={classNames(css.numCell, css.num)}
                >{`${order.price} руб`}</th>
                <th className={classNames(css.numCell, css.num)}>
                  {order.users_count}
                </th>
                <th className={classNames(css.numCell, css.num)}>
                  {order.time_spent}
                </th>
                <th className={classNames(css.numCell, css.num)}>
                  {order.order_number}
                </th>
                <th className={classNames(css.statusCell, css.num)}>
                  <Status content={order.status} />
                </th>
                <th
                  className={classNames(
                    css.tableCell,
                    css.lastCell,
                    css.metric,
                  )}
                >
                  <Link to="">{order.yandex_segment_id}</Link>
                </th>
              </tr>
            ))}
        </tbody>
      </table>

      <div className={css.pagination}>
        <Button
          variant="black"
          styles={classNames(css.backButton, css.paginationButton)}
          onClick={onBackButtonClick}
        >
          <ArrowBack className={css.arrowIcon} />
        </Button>
        {availablePages?.map((num) => (
          <Button
            variant="black"
            onClick={() => onPageNumberClick(num)}
            styles={
              num === page
                ? classNames(css.paginationPage, css.selected)
                : css.paginationPage
            }
          >
            {num}
          </Button>
        ))}

        <Button
          variant="black"
          styles={css.paginationButton}
          onClick={onForwardButtonClick}
        >
          <ArrowForward className={css.arrowIcon} />
        </Button>
      </div>
    </div>
  );
}

export default OrderHistory;
