import React, { useEffect, useRef, useState } from "react";
import css from "./styles.module.css";
import { Button, Mozaic } from "../../../components";
import { useNavigate } from "react-router-dom";
import { SQUARES_ROWS_LEAD_FULL } from "../../../constants";

function LeadSection() {
  const [scrolled, setScrolled] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom - 600 <= window.innerHeight;

      setScrolled(isInView);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onFollowLink = () => {
    navigate("/order");
  };
  return (
    <section ref={ref} className={css.container}>
      <div className={css.blueBox}>
        <div className={css.textBlock}>
          <div className={css.textContainer}>
            <p className={css.bigText}>AudienceHub — ваш новый источник дохода и ключ к эффективной рекламе</p>
            <p className={css.smallText}>
              Свяжитесь с нами, чтобы узнать больше, или зарегистрируйтесь для доступа к точным рекламным сегментам.
            </p>
          </div>
          <Button onClick={onFollowLink} variant="white" styles={css.button}>
            Заявка на демо
          </Button>
        </div>
        <div className={css.mosaic}>
          <Mozaic
            img={"./fullScreenMozaic.jpg"}
            isFull={true}
            squaresRows={SQUARES_ROWS_LEAD_FULL}
            squareJustifyContent="start"
            marginLeft
            showImageAnyWay
            wrapperStyles={{
              marginTop: "10px",
            }}
            startAnimation={scrolled}
          />
        </div>
      </div>
    </section>
  );
}

export default LeadSection;
