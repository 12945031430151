import React, { useEffect, useMemo, useState } from "react";
import {
  ArrowBack,
  ArrowForward,
  Button,
  Dropdown,
  MarketItem,
} from "../../components";
import { FaqSection, LeadSection } from "../Main";
import css from "./styles.module.css";
import classNames from "classnames";
import { useCategories, useSegments } from "../../api";

const timeOptions = [{ label: "> 1 минуты", value: "1" }];

const userCountOptions = [{ label: "> 1000 человек", value: "1000" }];

const Market = () => {
  const [page, setPage] = useState(1);

  const { data: categories } = useCategories();

  const categoriesNames = useMemo(
    () => categories?.data.data.map((category) => category.name) ?? [],
    [categories],
  );

  const [filters, setFilters] = useState({
    category: "",
    time_spent: "",
    user_count: "",
  });

  const { isLoading, data, isPreviousData, refetch } = useSegments(
    page,
    10,
    filters,
  );

  useEffect(() => {
    refetch();
  }, [filters, refetch]);

  const availablePages = useMemo(() => {
    const maxPages = data?.data.meta.page.total_pages ?? 0;

    if (page === 1) {
      return Array(Math.min(maxPages, 3))
        .fill(0)
        .map((_, i) => i + 1);
    }

    if (page === maxPages) {
      return Array(3)
        .fill(0)
        .map((_, i) => maxPages - 2 + i);
    }

    return Array(3)
      .fill(0)
      .map((_, i) => page - 1 + i);
  }, [data, page]);

  const onForwardButtonClick = () =>
    setPage((page) =>
      !isPreviousData && data?.data.meta.page.next_page ? page + 1 : page,
    );

  const onBackButtonClick = () => setPage((page) => Math.max(page - 1, 1));

  const onPageNumberClick = (num: number) => setPage(num);

  const onSelectOption = (id: string) => (value: string) => {
    setFilters((filters) => ({ ...filters, [id]: value }));
  };

  return (
    <>
      <section className={css.container}>
        <h2 className={css.title}>Маркет аудиторий</h2>
        <form className={css.filter}>
          <Dropdown
            classes={css.select}
            options={categoriesNames.map((name) => ({
              label: name,
              value: name,
            }))}
            label="Тематика"
            onChoose={onSelectOption("category")}
          />
          <Dropdown
            classes={css.select}
            options={timeOptions}
            label="Время на странице"
            onChoose={onSelectOption("time_spent")}
          />
          <Dropdown
            classes={css.select}
            options={userCountOptions}
            label="Число пользователей"
            onChoose={onSelectOption("user_count")}
          />
        </form>
        {isLoading ? null : (
          <>
            <div className={css.results}>
              {data?.data.data.map((item) => (
                <MarketItem
                  key={item.id}
                  layout="album"
                  tags={item.tags}
                  price={String(item.price)}
                  title={item.name}
                  userTime={item.time_spent}
                  userCount={String(item.users_count)}
                  articleUrl={item.article_link}
                  leadingUrl={`/${item.id}`}
                />
              ))}
            </div>

            <div className={css.pagination}>
              <Button
                variant="black"
                styles={classNames(css.backButton, css.paginationButton)}
                onClick={onBackButtonClick}
              >
                <ArrowBack className={css.arrowIcon} />
              </Button>
              {availablePages?.map((num) => (
                <Button
                  variant="black"
                  onClick={() => onPageNumberClick(num)}
                  styles={
                    num === page
                      ? classNames(css.paginationPage, css.selected)
                      : css.paginationPage
                  }
                >
                  {num}
                </Button>
              ))}

              <Button
                variant="black"
                styles={css.paginationButton}
                onClick={onForwardButtonClick}
              >
                <ArrowForward className={css.arrowIcon} />
              </Button>
            </div>
          </>
        )}
      </section>
      <FaqSection />
      <LeadSection />
    </>
  );
};

export default Market;
